@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

body {
  font-family: 'Montserrat', sans-serif;
  background-color: #f5f5f5;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

@layer base {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
